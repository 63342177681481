.contact__outer-wrap {
	position: relative;
	height: 101vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #363940;
}
.contact__outer-wrap a,
p {
	text-decoration: none;
	color: white;
	margin: 10px;
}

.contact__title {
	text-transform: uppercase;
	font-size: 3rem;
	color: white;
	font-weight: 800;
	margin: 10px;
}
