$tablet-width: 600px;
$desktop-width: 930px;
$small-desk-width: 1150px;

@mixin small-desk {
	@media (min-width: #{$desktop-width}) and (max-width: #{$small-desk-width}) {
		@content;
	}
}

@mixin iPad-Pro-portrait {
	@media (min-width: 950px) and (max-width: 1150px) and (min-height: 1200px) and (max-height: 1450px) {
		@content;
	}
}

@mixin tablet-portrait {
	@media (min-aspect-ratio: 9/13) and (max-aspect-ratio: 10/11) and (min-height: 800px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
		@content;
	}
}

@mixin surface-duo {
	@media (min-width: 600px) and (max-width: 800px) and (min-height: 500px) and (max-height: 700px) {
		@content;
	}
}
@mixin surface-duo-portrait {
	@media (min-width: 450px) and (max-width: 650px) and (min-height: 600px) and (max-height: 800px) {
		@content;
	}
}

@mixin phone {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin phone-landscape {
	@media (max-height: 500px) and (min-width: 700px) {
		@content;
	}
}
