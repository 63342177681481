@use './utilities/variables' as *;
@use './utilities/media-queries.scss' as *;

.nav-container {
	position: fixed;
	width: 100vw;
	background-color: none;
	transition: background-color 0.2s ease-out;
	z-index: 1;
	pointer-events: none;
}
.nav-container--show {
	background-color: white !important;
}

nav {
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	min-height: 79.2px;
	pointer-events: none;
}

.name-wrap {
	position: absolute;
	pointer-events: none;
	display: flex;
	align-items: center;
}

.nav__name--init {
	opacity: 0;
}
.nav__name {
	text-transform: uppercase;
	font-weight: 600;
	transition: 0.2s all ease-out 0.3s;
	opacity: 1;
	font-size: 1.15rem;

	@include phone {
		font-size: 0.85rem;
	}
}
.nav__name--hide {
	opacity: 0 !important;
	transition: 0.035s all ease-out;
}
.nav__name--hide-scroll {
	opacity: 0 !important;
	transition: 0.35s all ease-out;
}

.nav__links {
	display: flex;
	opacity: 0;
	justify-content: center;
	padding: calc(#{$nav-height}/ 2) 0;
	color: $text;
	transition: opacity 0.3s ease-out;
	pointer-events: none;
	@include phone {
		flex-direction: column;
		align-items: center;
		transition: all 0.2s ease-out;
	}
}

.nav__links--show {
	display: flex;
	opacity: 1;
	justify-content: center;
	padding: calc(#{$nav-height}/ 2) 0;
	color: $text;
	pointer-events: auto;
	transition: all 0.2s ease-out;
	@include phone {
		flex-direction: column;
		align-items: center;
		transition: all 0.2s ease-out;
	}
}

.nav__links .link {
	text-decoration: none;
	cursor: pointer;
	margin: 0 30px;
	color: $text;
	list-style-type: none;
	font-weight: 700;
	font-size: $nav-font-size;
	transition: opacity 0.3s ease;
	@include phone {
		padding: 0px;
		transition: padding 0.2s ease-out, height 0.2s ease-out;
		height: 0px;
	}
}
.link--show {
	@include phone {
		padding: 30px 0px !important;
		transition: padding 0.2s ease-out, height 0.2s ease-out;
		height: auto !important;
	}
}

.nav__links p:hover {
	opacity: 0.5;
}

.hamburger {
	position: absolute;
	left: 0;
	width: 40px;
	height: calc(#{$nav-height} + #{$nav-font-size});
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	z-index: 9999;
	opacity: 0;
	margin-left: 50px;
	transition: all 0.3s ease-out 1s;
	pointer-events: all;

	@include phone {
		top: 0;
	}
}
.hamburger--show {
	opacity: 1;
	margin-left: 20px;
}

.ham__lines {
	background-color: $text;
	height: 5px;
	margin: 5px;
	transition: all 0.3s ease-out;
}
.ham__lines--show {
	background-color: white;
}

.ham__sides__cont {
	position: absolute;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.ham__sides {
	background: $text;
	height: 1px;
	width: 35px;
	margin: 18px 0px;
	position: relative;
	opacity: 0;
	transition: opacity 0.1s ease-out;

	@include phone {
		margin: 12px 0px;
		width: 25px;
	}
}
.ham__sides--show {
	opacity: 0.1;
	transition: opacity 0.7s ease-out 0.4s;
}

.ham__one {
	width: 20px;
}

.ham__two {
	width: 25px;
}

.ham__lines--click {
	background-color: $purple;
	height: 5px;
	margin: -2.5px;
	border-radius: 100%;
	width: 5px !important;
	transition: all 0.3s ease-out;
}
