@use './utilities/variables' as *;
@use './utilities/media-queries.scss' as *;

.bg-cont {
	display: flex;
	justify-content: center;
	background-color: transparent !important;
	transition: background-color 0.7s ease-out;
}
.bg-cont--init {
	background-color: rgba(12, 12, 12, 0.801);
}

.bg {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 1rem;
	z-index: -1;
	position: relative;
	height: 700px;
	opacity: 0;
	transition: all 0.4s ease-out;
	margin-left: -20px;
	width: 1080px;

	@include tablet {
		width: 800px;
	}

	@include phone {
		flex-direction: column;
		margin-left: 0 !important;
		justify-content: center;
		width: 100vw;
		padding-top: 25px;
	}
	@include phone-landscape {
		height: 100vh;
		width: 600px;
	}
}
.bg--show {
	opacity: 1;
	margin-left: -80px;
	transition: all 0.25s ease-out;
}
.bg--show-init {
	opacity: 1;
	margin-left: -80px;
	transition: all 0.8s ease-out 0.3s;
}

.bg__headline-cont {
	@include phone {
		display: flex;
		justify-content: center;
	}
}
.bg__headline {
	font-weight: 800;
	text-align: end;
	font-size: 3rem;
	width: 500px;
	letter-spacing: 0.02em;

	@include tablet {
		font-size: 1.5rem;
		width: 300px;
	}

	@include phone {
		font-size: 1.8rem;
		width: 210px;
		text-align: center;
		text-align-last: justify;
		letter-spacing: 0.03em;
	}
}

.bg__home-line {
	width: 5px;
	height: 98px;
	background-color: #735d59;

	@include tablet {
		width: 2px;
	}
	@include phone {
		width: 206px;
		height: 1px;
		margin: 12px;
	}
}

.bg-image-wrap {
	display: flex;
	justify-content: center;
}

.bg-image-wrap img {
	border-radius: 15px;
	filter: grayscale(0.5);
	width: 330px;
	align-self: start;

	@include tablet {
		width: 300px;
	}

	@include phone {
		width: 210px;
		border-radius: 5px;
		margin-top: 5px;
	}
	@include phone-landscape {
		width: 40vh;
	}
}
