$background: white;
$text: #161516;
$nav-height: 60px;
$nav-font-size: 1rem;
$purple: #161516;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

body {
	background-color: $background;
	font-family: "Inter", sans-serif;
	font-weight: 600;
}

.snap-parent {
	height: 100vh;
}

