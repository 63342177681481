@use './utilities/variables' as *;
@use './utilities/media-queries.scss' as *;

$proj-two-width: 45vw;

.entire-projects {
	position: relative;
}

.proj-section-cont {
	opacity: 0;
	transform: translateY(-2.5vh);
	transition: all 0.4s ease-out;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* mobile viewport bug fix */
	// height: -webkit-fill-available;
}
.proj-section-cont--show {
	opacity: 1;
	transform: translateY(0vh);
	transition: all 0.4s ease-out;
}

.projects-section {
	position: relative;
	overflow: hidden;
	/* NEED HEIGHT FOR SCROLLBAR HIDE */
	height: 100vh;
	/* mobile viewport bug fix */
	// height: -webkit-fill-available;
}

.proj-title-cont {
	position: absolute;
	height: 100%;
	/* mobile viewport bug fix */
	// height: -webkit-fill-available;
}

.projects-title {
	top: 0px;
	position: sticky;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 3rem;
	margin-top: 15vh;
	z-index: 1;
	color: white;
	@include phone {
		margin-top: 24vh;
		font-size: 2rem;
	}
	@include phone-landscape {
		margin-top: 15vh;
		font-size: 2rem;
	}
	@include tablet-portrait {
		margin-top: 200px;
	}
	@include surface-duo {
		margin-top: 12vh;
	}
}

.arrows {
	position: absolute;
	width: 100vw;
	display: flex;
	align-items: center;
}

.arrow {
	position: absolute;
	height: 60px;
	width: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin: 50px;
	z-index: 5;
	cursor: pointer;
	transition: all 0.15s ease-out;
	@include phone {
		margin: 20px;
		transform: scale(0.35);
	}
}
.arrow:hover {
	@media (hover: hover) and (pointer: fine) {
		opacity: 0.5;
		transform: scale(0.95);
		transition: all 0.15s ease-out;
	}
}
.arrow--hide {
	opacity: 0;
	pointer-events: none;
	transform: scale(0);
}

.left-arrow {
	left: 0px;
}
.right-arrow {
	right: 0px;
}

.arrow__arm {
	position: relative;
	background-color: black;
	width: 45px;
	height: 3px;
	margin: -2px;
	border-radius: 10px;
}
.arrow__arm.right {
	transform-origin: right;
}
.arrow__arm.left {
	transform-origin: left;
}
.left.arrow__arm.top {
	transform: rotate(-35deg);
}
.left.arrow__arm.bottom {
	transform: rotate(35deg);
}
.right.arrow__arm.top {
	transform: rotate(35deg);
}
.right.arrow__arm.bottom {
	transform: rotate(-35deg);
}

.projects {
	position: relative;
	width: 100vw;
	/* mobile viewport bug fix */
	// height: -webkit-fill-available;
	background-color: transparent;
	display: flex;
	// flex-direction: column;
	// justify-content: flex-start;
	align-items: center;
	overflow-x: scroll;
	overflow-y: hidden; /* Fix mobile landscape scroll */
	transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	scroll-snap-type: x mandatory;
}
.projects--lock {
	overflow: visible;
	transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.projects--scroll {
	transform: translateX(calc(-70vw - #{$proj-two-width} / 2));
	// transform: translateX(calc(-80vw));
	transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.projs {
	/* NEED HEIGHT FOR SCROLLBAR HIDE */
	height: 100vh;
	min-width: 100vw;
	scroll-snap-align: center;
}
.projs__paper {
	height: 80vh;
	width: 60vw;
	background-color: rgb(14, 14, 14);
	box-shadow: 2px 5px 15px -2px rgba(0, 0, 0, 0.301);
	position: absolute;

	@include phone {
		height: 63vh;
	}
}

.projs .image img {
	filter: grayscale(0.3);
	opacity: 1;
	cursor: pointer;
	transition: opacity 0.25s ease-out, transform 1s ease-out;
}

.projs .image img:hover {
	opacity: 0.75;
	transform: scale(1.05);
	transition: opacity 0.15s ease-out, transform 1s ease-out;
}

.projects__one {
	// position: absolute;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.projs__text {
	display: flex;
	justify-content: center;
	padding: 15px;
	font-size: 1.2em;
	font-weight: 800;
	z-index: 1;
	color: white;
	@include phone {
		font-size: 0.8em;
	}
	@include iPad-Pro-portrait {
		font-size: 1.5em;
	}
}

.proj__one__img img {
	height: 30vw;
	max-height: 40vh;
	// width: 35vw;
	border-radius: 10px;

	@include small-desk {
		height: 35vw;
	}
	@include tablet {
		height: 35vw;
	}

	@include phone {
		height: 32vw;
	}
	@include phone-landscape {
		height: 43vh;
		margin-top: 13vh;
	}
	@include surface-duo {
		height: 28vw;
		margin-top: 4vw;
	}
	@include tablet-portrait {
		height: 30vw;
	}
	@include surface-duo-portrait {
	}
}

.projects__two {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	// left: 0vw;
}

.proj__two__img img {
	width: $proj-two-width;
	max-width: 30vh;
	border-radius: 5px;

	@include phone {
		width: 40vw;
		margin-top: 16vw;
	}
	@include phone-landscape {
		width: 30vh;
		margin-top: 17vh;
	}
	@include surface-duo {
		width: 20vw;
		margin-top: 7vw;
	}
	@include surface-duo-portrait {
		width: 35vw;
	}
	@include iPad-Pro-portrait {
		width: 40vw;
		margin-top: 5vw;
	}
}

.proj__overlay {
	position: absolute;
	width: 450px;
	height: 580px;
	background-color: rgb(12, 12, 12);
	box-shadow: 2px 5px 19px -1px rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: default;
	z-index: 6;
	opacity: 0;
	pointer-events: none;
	transform: translateY(30px);
	transition: all 0.3s ease-out;

	@include phone {
		width: 350px;
	}
}
.proj__overlay--show {
	opacity: 1;
	pointer-events: all;
	transform: translateY(0px);
	transition: all 0.3s ease-out;
}

.proj__overlay .git {
	position: absolute;
	bottom: 15px;
	right: 20px;
	text-decoration: underline;
	color: rgb(255, 255, 255);
	background-color: rgba(36, 30, 41, 0.384);
	padding: 10px;
	border-radius: 1.5px;
	transition: all 0.2s ease-out;
}
.proj__overlay .git:hover {
	transform: scale(1.025);
	background-color: rgba(50, 44, 54, 0.384);
	transition: all 0.2s ease-out;
}

.overlay__title {
	color: white;
	margin: 80px;
	margin-bottom: 0;
	font-size: 2rem;
	text-align: center;
	transition: opacity 0.3s ease-out, transform 0.2s ease-out;

	@include phone {
		font-size: 1.5rem;
		width: 250px;
		margin-top: 60px;
	}
}
.overlay__title:hover {
	opacity: 0.5;
	transform: scale(1.05);
	transition: opacity 0.3s ease-out, transform 0.2s ease-out;
}

.overlay__title.two {
	margin-top: 50px;
}

.proj__video {
	width: 85%;
	position: relative;
	transition: all 0.3s ease-out;
	margin: 15px 0px;
	transform: scale(1);
	box-shadow: 1px 1px 20px 2px rgba(205, 205, 205, 0.15);

	&--hide {
		opacity: 0;
		pointer-events: none;
		position: absolute;
		border-radius: 50px;
		transform: scaleX(1);
		transition: all 0.5s ease-out, opacity 0.3s ease-out;
		// transition: transform 1s ease-out, opacity 0.3s ease-out;
	}
}

.see-more {
	text-decoration: "none";
	color: white;
	width: auto;
	display: inline;
	text-decoration: underline;
	cursor: pointer;
	margin-left: 0;
	padding: 0 0.5em;
	// background-color: rgb(21, 19, 23);
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
	transition: all 0.2s ease-out;
	border-radius: 0.1em;
	font-size: 0.8em;
	animation: 5s ease-in infinite pulse;

	&:hover {
		opacity: 0.7;
		transition: all 0.2s ease-out;
	}
}
@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.15;
	}
	100% {
		opacity: 1;
	}
}

.overlay__subtext {
	color: white;
	margin: 80px;
	margin-top: 50px;
	text-align: left;
	line-height: 1.3rem;
	transition: all 0.3s ease-out;

	@include phone {
		font-size: 0.8rem;
		margin-top: 10px;
	}

	&--truncate {
		margin-top: 5px;
		transition: all 0.3s ease-out;
	}
}

.overlay__subtext.two {
	margin-top: 20px;
	@include phone {
		width: 215px;
		margin-top: 20px;
	}
}

.close-overlays {
	z-index: 5;
	width: 100vw;
	height: 100%;
	/* mobile viewport bug fix */
	// height: -webkit-fill-available;
	left: 0;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	opacity: 0;
	transition: all 0.5s ease-out;
}
.close-overlays--show {
	opacity: 1;
	pointer-events: all;
}
