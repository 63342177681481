@use './utilities/variables' as *;
@use './utilities/media-queries.scss' as *;

.mid-content {
	background: $text;
	height: 100vh;
	display: flex;
	justify-content: center;
}

.mid-wrap {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 1080px;
	height: 100%;

	@include tablet {
		width: 600px;
	}
}

.mid-wrap__image {
	position: relative;
}

.mid-wrap__image img {
	width: 250px;
	border-radius: 15px;
	filter: grayscale(0.85);

	@include tablet {
		width: 160px;
		margin-left: 20px;
	}

	@include phone {
		display: none;
	}
}

.mid-wrap__text-wrap {
	width: 430px;

	@include tablet {
		display: flex;
		justify-content: center;
	}

	@include phone {
		display: flex;
		justify-content: center;
	}
}

.mid-wrap__text {
	color: #e6faf9;
	font-weight: 800;
	letter-spacing: 0.02em;
	font-size: 2rem;
	opacity: 0;
	text-transform: uppercase;
	text-align: justify;
	text-justify: inter-character;
	text-align-last: justify;
	line-height: 3.2rem;
	transition: opacity 0.3s ease-out 0s;

	@include tablet {
		font-size: 1.5rem;
		width: 360px;
	}

	@include phone {
		font-size: 1rem;
		width: 200px;
	}
}

.mid-wrap__text--show {
	opacity: 1;
}
